// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-affiliate-index-jsx": () => import("./../../../src/pages/affiliate/index.jsx" /* webpackChunkName: "component---src-pages-affiliate-index-jsx" */),
  "component---src-pages-allbet-drawredpacket-9000000000-jsx": () => import("./../../../src/pages/allbet/drawredpacket9000000000.jsx" /* webpackChunkName: "component---src-pages-allbet-drawredpacket-9000000000-jsx" */),
  "component---src-pages-casinos-online-jsx": () => import("./../../../src/pages/casinos-online.jsx" /* webpackChunkName: "component---src-pages-casinos-online-jsx" */),
  "component---src-pages-e-sports-jsx": () => import("./../../../src/pages/e-sports.jsx" /* webpackChunkName: "component---src-pages-e-sports-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-new-promotions-bonus-20-casino-jsx": () => import("./../../../src/pages/new-promotions/bonus20-casino.jsx" /* webpackChunkName: "component---src-pages-new-promotions-bonus-20-casino-jsx" */),
  "component---src-pages-new-promotions-bonus-20-slot-jsx": () => import("./../../../src/pages/new-promotions/bonus20-slot.jsx" /* webpackChunkName: "component---src-pages-new-promotions-bonus-20-slot-jsx" */),
  "component---src-pages-new-promotions-bonus-20-sport-jsx": () => import("./../../../src/pages/new-promotions/bonus20-sport.jsx" /* webpackChunkName: "component---src-pages-new-promotions-bonus-20-sport-jsx" */),
  "component---src-pages-new-promotions-daily-slot-jsx": () => import("./../../../src/pages/new-promotions/daily-slot.jsx" /* webpackChunkName: "component---src-pages-new-promotions-daily-slot-jsx" */),
  "component---src-pages-new-promotions-slot-500-1000-jsx": () => import("./../../../src/pages/new-promotions/slot-500-1000.jsx" /* webpackChunkName: "component---src-pages-new-promotions-slot-500-1000-jsx" */),
  "component---src-pages-new-promotions-slot-500-300-499-jsx": () => import("./../../../src/pages/new-promotions/slot-500-300-499.jsx" /* webpackChunkName: "component---src-pages-new-promotions-slot-500-300-499-jsx" */),
  "component---src-pages-new-promotions-slot-500-50-299-jsx": () => import("./../../../src/pages/new-promotions/slot-500-50-299.jsx" /* webpackChunkName: "component---src-pages-new-promotions-slot-500-50-299-jsx" */),
  "component---src-pages-new-promotions-slot-500-500-999-jsx": () => import("./../../../src/pages/new-promotions/slot-500-500-999.jsx" /* webpackChunkName: "component---src-pages-new-promotions-slot-500-500-999-jsx" */),
  "component---src-pages-new-promotions-sport-500-1000-jsx": () => import("./../../../src/pages/new-promotions/sport-500-1000.jsx" /* webpackChunkName: "component---src-pages-new-promotions-sport-500-1000-jsx" */),
  "component---src-pages-new-promotions-sport-500-300-499-jsx": () => import("./../../../src/pages/new-promotions/sport-500-300-499.jsx" /* webpackChunkName: "component---src-pages-new-promotions-sport-500-300-499-jsx" */),
  "component---src-pages-new-promotions-sport-500-50-299-jsx": () => import("./../../../src/pages/new-promotions/sport-500-50-299.jsx" /* webpackChunkName: "component---src-pages-new-promotions-sport-500-50-299-jsx" */),
  "component---src-pages-new-promotions-sport-500-500-999-jsx": () => import("./../../../src/pages/new-promotions/sport-500-500-999.jsx" /* webpackChunkName: "component---src-pages-new-promotions-sport-500-500-999-jsx" */),
  "component---src-pages-promotions-3-3-deposit-100-receive-300-jsx": () => import("./../../../src/pages/promotions/3-3-deposit-100-receive-300.jsx" /* webpackChunkName: "component---src-pages-promotions-3-3-deposit-100-receive-300-jsx" */),
  "component---src-pages-promotions-918-kiss-and-pussy-888-welcome-bonus-jsx": () => import("./../../../src/pages/promotions/918kiss-and-pussy888-welcome-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-918-kiss-and-pussy-888-welcome-bonus-jsx" */),
  "component---src-pages-promotions-918-kiss-mega-888-xe-88-pussy-888-welcome-bonus-jsx": () => import("./../../../src/pages/promotions/918kiss-mega888-xe88-pussy888-welcome-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-918-kiss-mega-888-xe-88-pussy-888-welcome-bonus-jsx" */),
  "component---src-pages-promotions-918-kiss-pussy-888-daily-bonus-unlimited-jsx": () => import("./../../../src/pages/promotions/918kiss-pussy888-daily-bonus-unlimited.jsx" /* webpackChunkName: "component---src-pages-promotions-918-kiss-pussy-888-daily-bonus-unlimited-jsx" */),
  "component---src-pages-promotions-allaffiliate-jsx": () => import("./../../../src/pages/promotions/allaffiliate.jsx" /* webpackChunkName: "component---src-pages-promotions-allaffiliate-jsx" */),
  "component---src-pages-promotions-allbirthday-jsx": () => import("./../../../src/pages/promotions/allbirthday.jsx" /* webpackChunkName: "component---src-pages-promotions-allbirthday-jsx" */),
  "component---src-pages-promotions-allreward-20-percent-jsx": () => import("./../../../src/pages/promotions/allreward-20percent.jsx" /* webpackChunkName: "component---src-pages-promotions-allreward-20-percent-jsx" */),
  "component---src-pages-promotions-allrewards-jsx": () => import("./../../../src/pages/promotions/allrewards.jsx" /* webpackChunkName: "component---src-pages-promotions-allrewards-jsx" */),
  "component---src-pages-promotions-alltopfriends-jsx": () => import("./../../../src/pages/promotions/alltopfriends.jsx" /* webpackChunkName: "component---src-pages-promotions-alltopfriends-jsx" */),
  "component---src-pages-promotions-cashback-jsx": () => import("./../../../src/pages/promotions/cashback.jsx" /* webpackChunkName: "component---src-pages-promotions-cashback-jsx" */),
  "component---src-pages-promotions-casino-cashback-jsx": () => import("./../../../src/pages/promotions/casino-cashback.jsx" /* webpackChunkName: "component---src-pages-promotions-casino-cashback-jsx" */),
  "component---src-pages-promotions-casino-daily-bonus-jsx": () => import("./../../../src/pages/promotions/casino-daily-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-casino-daily-bonus-jsx" */),
  "component---src-pages-promotions-casino-daily-bonus-unlimited-jsx": () => import("./../../../src/pages/promotions/casino-daily-bonus-unlimited.jsx" /* webpackChunkName: "component---src-pages-promotions-casino-daily-bonus-unlimited-jsx" */),
  "component---src-pages-promotions-esport-cashback-jsx": () => import("./../../../src/pages/promotions/esport-cashback.jsx" /* webpackChunkName: "component---src-pages-promotions-esport-cashback-jsx" */),
  "component---src-pages-promotions-esport-daily-bonus-jsx": () => import("./../../../src/pages/promotions/esport-daily-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-esport-daily-bonus-jsx" */),
  "component---src-pages-promotions-esport-welcome-bonus-jsx": () => import("./../../../src/pages/promotions/esport-welcome-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-esport-welcome-bonus-jsx" */),
  "component---src-pages-promotions-index-jsx": () => import("./../../../src/pages/promotions/index.jsx" /* webpackChunkName: "component---src-pages-promotions-index-jsx" */),
  "component---src-pages-promotions-new-casino-birthday-jsx": () => import("./../../../src/pages/promotions/new-casino-birthday.jsx" /* webpackChunkName: "component---src-pages-promotions-new-casino-birthday-jsx" */),
  "component---src-pages-promotions-new-casino-daily-jsx": () => import("./../../../src/pages/promotions/new-casino-daily.jsx" /* webpackChunkName: "component---src-pages-promotions-new-casino-daily-jsx" */),
  "component---src-pages-promotions-new-casino-special-time-jsx": () => import("./../../../src/pages/promotions/new-casino-special-time.jsx" /* webpackChunkName: "component---src-pages-promotions-new-casino-special-time-jsx" */),
  "component---src-pages-promotions-new-member-casino-1-jsx": () => import("./../../../src/pages/promotions/new-member-casino-1.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-casino-1-jsx" */),
  "component---src-pages-promotions-new-member-casino-2-jsx": () => import("./../../../src/pages/promotions/new-member-casino-2.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-casino-2-jsx" */),
  "component---src-pages-promotions-new-member-casino-3-jsx": () => import("./../../../src/pages/promotions/new-member-casino-3.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-casino-3-jsx" */),
  "component---src-pages-promotions-new-member-casino-4-jsx": () => import("./../../../src/pages/promotions/new-member-casino-4.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-casino-4-jsx" */),
  "component---src-pages-promotions-new-member-casino-5-jsx": () => import("./../../../src/pages/promotions/new-member-casino-5.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-casino-5-jsx" */),
  "component---src-pages-promotions-new-member-casino-6-jsx": () => import("./../../../src/pages/promotions/new-member-casino-6.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-casino-6-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-1-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-1.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-1-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-2-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-2.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-2-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-3-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-3.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-3-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-4-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-4.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-4-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-5-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-5.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-5-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-6-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-6.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-6-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-7-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-7.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-7-jsx" */),
  "component---src-pages-promotions-new-member-slot-casino-8-jsx": () => import("./../../../src/pages/promotions/new-member-slot-casino-8.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-slot-casino-8-jsx" */),
  "component---src-pages-promotions-new-member-sport-1-jsx": () => import("./../../../src/pages/promotions/new-member-sport-1.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-sport-1-jsx" */),
  "component---src-pages-promotions-new-member-sport-2-jsx": () => import("./../../../src/pages/promotions/new-member-sport-2.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-sport-2-jsx" */),
  "component---src-pages-promotions-new-member-sport-3-jsx": () => import("./../../../src/pages/promotions/new-member-sport-3.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-sport-3-jsx" */),
  "component---src-pages-promotions-new-member-sport-4-jsx": () => import("./../../../src/pages/promotions/new-member-sport-4.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-sport-4-jsx" */),
  "component---src-pages-promotions-new-member-sport-5-jsx": () => import("./../../../src/pages/promotions/new-member-sport-5.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-sport-5-jsx" */),
  "component---src-pages-promotions-new-member-sport-6-jsx": () => import("./../../../src/pages/promotions/new-member-sport-6.jsx" /* webpackChunkName: "component---src-pages-promotions-new-member-sport-6-jsx" */),
  "component---src-pages-promotions-new-slot-birthday-jsx": () => import("./../../../src/pages/promotions/new-slot-birthday.jsx" /* webpackChunkName: "component---src-pages-promotions-new-slot-birthday-jsx" */),
  "component---src-pages-promotions-new-slot-daily-jsx": () => import("./../../../src/pages/promotions/new-slot-daily.jsx" /* webpackChunkName: "component---src-pages-promotions-new-slot-daily-jsx" */),
  "component---src-pages-promotions-new-slot-daly-30-percent-jsx": () => import("./../../../src/pages/promotions/new-slot-daly-30-percent.jsx" /* webpackChunkName: "component---src-pages-promotions-new-slot-daly-30-percent-jsx" */),
  "component---src-pages-promotions-new-slot-special-time-jsx": () => import("./../../../src/pages/promotions/new-slot-special-time.jsx" /* webpackChunkName: "component---src-pages-promotions-new-slot-special-time-jsx" */),
  "component---src-pages-promotions-new-sport-birthday-jsx": () => import("./../../../src/pages/promotions/new-sport-birthday.jsx" /* webpackChunkName: "component---src-pages-promotions-new-sport-birthday-jsx" */),
  "component---src-pages-promotions-new-sport-daily-jsx": () => import("./../../../src/pages/promotions/new-sport-daily.jsx" /* webpackChunkName: "component---src-pages-promotions-new-sport-daily-jsx" */),
  "component---src-pages-promotions-new-sport-special-time-jsx": () => import("./../../../src/pages/promotions/new-sport-special-time.jsx" /* webpackChunkName: "component---src-pages-promotions-new-sport-special-time-jsx" */),
  "component---src-pages-promotions-pragmatic-play-daily-wins-cash-drop-jsx": () => import("./../../../src/pages/promotions/pragmatic-play-daily-wins-cash-drop.jsx" /* webpackChunkName: "component---src-pages-promotions-pragmatic-play-daily-wins-cash-drop-jsx" */),
  "component---src-pages-promotions-promotion-16-jsx": () => import("./../../../src/pages/promotions/promotion-16.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-16-jsx" */),
  "component---src-pages-promotions-promotion-2-jsx": () => import("./../../../src/pages/promotions/promotion-2.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-2-jsx" */),
  "component---src-pages-promotions-promotion-3-jsx": () => import("./../../../src/pages/promotions/promotion-3.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-3-jsx" */),
  "component---src-pages-promotions-promotion-9-jsx": () => import("./../../../src/pages/promotions/promotion-9.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-9-jsx" */),
  "component---src-pages-promotions-promotion-casinos-jsx": () => import("./../../../src/pages/promotions/promotion-casinos.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-casinos-jsx" */),
  "component---src-pages-promotions-promotion-esports-jsx": () => import("./../../../src/pages/promotions/promotion-esports.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-esports-jsx" */),
  "component---src-pages-promotions-promotion-latest-jsx": () => import("./../../../src/pages/promotions/promotion-latest.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-latest-jsx" */),
  "component---src-pages-promotions-promotion-live-dealer-welcome-bonus-combo-180-percent-jsx": () => import("./../../../src/pages/promotions/promotion-live-dealer-welcome-bonus-combo-180-percent.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-live-dealer-welcome-bonus-combo-180-percent-jsx" */),
  "component---src-pages-promotions-promotion-recommend-jsx": () => import("./../../../src/pages/promotions/promotion-recommend.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-recommend-jsx" */),
  "component---src-pages-promotions-promotion-slots-jsx": () => import("./../../../src/pages/promotions/promotion-slots.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-slots-jsx" */),
  "component---src-pages-promotions-promotion-sports-jsx": () => import("./../../../src/pages/promotions/promotion-sports.jsx" /* webpackChunkName: "component---src-pages-promotions-promotion-sports-jsx" */),
  "component---src-pages-promotions-slot-cashback-jsx": () => import("./../../../src/pages/promotions/slot-cashback.jsx" /* webpackChunkName: "component---src-pages-promotions-slot-cashback-jsx" */),
  "component---src-pages-promotions-slot-daily-bonus-unlimited-jsx": () => import("./../../../src/pages/promotions/slot-daily-bonus-unlimited.jsx" /* webpackChunkName: "component---src-pages-promotions-slot-daily-bonus-unlimited-jsx" */),
  "component---src-pages-promotions-slot-welcome-bonus-jsx": () => import("./../../../src/pages/promotions/slot-welcome-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-slot-welcome-bonus-jsx" */),
  "component---src-pages-promotions-slots-and-games-daily-bonus-jsx": () => import("./../../../src/pages/promotions/slots-and-games-daily-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-slots-and-games-daily-bonus-jsx" */),
  "component---src-pages-promotions-slots-and-games-welcome-bonus-jsx": () => import("./../../../src/pages/promotions/slots-and-games-welcome-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-slots-and-games-welcome-bonus-jsx" */),
  "component---src-pages-promotions-slots-daily-bonus-jsx": () => import("./../../../src/pages/promotions/slots-daily-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-slots-daily-bonus-jsx" */),
  "component---src-pages-promotions-sport-cashback-jsx": () => import("./../../../src/pages/promotions/sport-cashback.jsx" /* webpackChunkName: "component---src-pages-promotions-sport-cashback-jsx" */),
  "component---src-pages-promotions-sport-daily-bonus-jsx": () => import("./../../../src/pages/promotions/sport-daily-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-sport-daily-bonus-jsx" */),
  "component---src-pages-promotions-sports-welcome-bonus-jsx": () => import("./../../../src/pages/promotions/sports-welcome-bonus.jsx" /* webpackChunkName: "component---src-pages-promotions-sports-welcome-bonus-jsx" */),
  "component---src-pages-promotions-unlimited-casino-cash-back-jsx": () => import("./../../../src/pages/promotions/unlimited-casino-cash-back.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-casino-cash-back-jsx" */),
  "component---src-pages-promotions-unlimited-casino-rebate-jsx": () => import("./../../../src/pages/promotions/unlimited-casino-rebate.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-casino-rebate-jsx" */),
  "component---src-pages-promotions-unlimited-ezgame-cash-back-jsx": () => import("./../../../src/pages/promotions/unlimited-ezgame-cash-back.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-ezgame-cash-back-jsx" */),
  "component---src-pages-promotions-unlimited-ezgame-rebate-jsx": () => import("./../../../src/pages/promotions/unlimited-ezgame-rebate.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-ezgame-rebate-jsx" */),
  "component---src-pages-promotions-unlimited-slot-and-game-cash-back-jsx": () => import("./../../../src/pages/promotions/unlimited-slot-and-game-cash-back.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-slot-and-game-cash-back-jsx" */),
  "component---src-pages-promotions-unlimited-slot-and-game-rebate-jsx": () => import("./../../../src/pages/promotions/unlimited-slot-and-game-rebate.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-slot-and-game-rebate-jsx" */),
  "component---src-pages-promotions-unlimited-sport-cash-back-jsx": () => import("./../../../src/pages/promotions/unlimited-sport-cash-back.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-sport-cash-back-jsx" */),
  "component---src-pages-promotions-unlimited-sport-rebate-jsx": () => import("./../../../src/pages/promotions/unlimited-sport-rebate.jsx" /* webpackChunkName: "component---src-pages-promotions-unlimited-sport-rebate-jsx" */),
  "component---src-pages-promotions-welcome-combo-slot-300-percent-jsx": () => import("./../../../src/pages/promotions/welcome-combo-slot-300-percent.jsx" /* webpackChunkName: "component---src-pages-promotions-welcome-combo-slot-300-percent-jsx" */),
  "component---src-pages-slots-jsx": () => import("./../../../src/pages/slots.jsx" /* webpackChunkName: "component---src-pages-slots-jsx" */),
  "component---src-pages-sports-jsx": () => import("./../../../src/pages/sports.jsx" /* webpackChunkName: "component---src-pages-sports-jsx" */)
}

